<template>
  <div>
    <b-table id="tabelaListaDeMatriculas" striped :items="items" :fields="fields" :busy="isBusy">
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong> Carregando...</strong>
        </div>
      </template>

      <template #cell(selecione)="row">
        <b-button
          v-if="validaQuantidadeMargem(row.item)"
          variant="primary"
          size="sm"
          class="mr-2"
          @click="alterarParaMatriculaSelecionada(row.item.id)"
          v-b-tooltip.hover.top="'Selecione'"
        >
          <feather-icon icon="CheckIcon" size="18" />
        </b-button>

        <feather-icon
          v-else-if="parametro.seguradoId == row.item.id"
          v-b-tooltip.hover.bottom="'Matrícula atual vinculada ao contrato!'"
          icon="AlertCircleIcon"
          size="18"
        />

        <feather-icon
          v-else
          v-b-tooltip.hover.bottom="'Esta matrícula não possui margem disponível para alteração!'"
          icon="AlertCircleIcon"
          size="18"
        />
      </template>
    </b-table>

    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData, formatarValor } from '@/libs/utils'

  export default {
    props: {
      parametro: {
        type: Object,
        required: true,
      },
    },
    components: {
      formatarValor,
      AutenticaSenha,
    },
    data() {
      return {
        isBusy: false,
        items: [],
        fields: [
          { key: 'selecione', label: 'Selecione' },
          { key: 'matricula', label: 'Matrícula' },
          { key: 'nome', label: 'Nome' },
          {
            key: 'margemDisponivel',
            label: 'Margem Disponível',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'margemUtilizada',
            label: 'Margem Utilizada',
            formatter: (value) => (value ? formatarValor(value) : formatarValor(0)),
          },
          {
            key: 'dataAlteracaoVinculo',
            label: 'Data de Alteração de Vínculo',
            formatter: (value) => (value ? formatarData(value) : formatarData(0)),
          },
          { key: 'orgao', label: 'Órgão' },
        ],
        seguradoId: null,
      }
    },
    async mounted() {
      await this.carregarListaDeMatriculas()
    },
    methods: {
      async carregarListaDeMatriculas() {
        this.isBusy = true
        const parametro = {
          tomadorId: this.parametro.tomadorId,
        }

        useJwt
          .post('cadastro/tomador/GetListaDeSegurados', parametro)
          .then((response) => {
            this.items = response.data
          })
          .catch((error) => {
            console.error(error)
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      validaQuantidadeMargem(param) {
        if (this.parametro.seguradoId == param.id) return false

        const valorDaParcela = this.parametro.valorDaParcela
        return param.margemDisponivel >= valorDaParcela ? true : false
      },
      alterarParaMatriculaSelecionada(seguradoId) {
        this.seguradoId = seguradoId
        this.$refs['modal-auth-senha'].show()
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
          this.salvar()
        }
      },
      salvar() {
        const parametro = {
          contratoId: this.parametro.contratoId,
          novoSeguradoId: this.seguradoId,
          anteriorSeguradoId: this.parametro.seguradoId,
        }

        useJwt
          .post('cadastro/tomador/AlterarSeguradoNoContrato', parametro)
          .then((response) => {
            this.$message.success('Matrícula vinculada alterada com sucesso!')
            this.$emit('fecharModalAlterarMatriculaVinculada')
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Ocorreu um erro ao alterar matrícula vinculada!')
          })
          .finally(() => {
            this.isBusy = false
            this.seguradoId = null
          })
      },
    },
  }
</script>
