<template>
  <div class="mt-1">
    <b-row>
      <b-col md="6">
        <b-card>
          <b-card-title>
            Documentos
            <feather-icon id="iconInfoDocs" icon="InfoIcon" style="margin-bottom: 3px" />
            <b-tooltip placement="bottom" target="iconInfoDocs" noninteractive
              >Os Documentos podem ser anexados clicando abaixo nos seus devidos nomes.</b-tooltip
            >
          </b-card-title>
          <b-row>
            <b-col md="12">
              <b-overlay :show="loading" rounded="sm" :opacity="0.3">
                <div
                  v-for="item in itemsDocumentoCheckList"
                  :key="item.id"
                  class="d-flex justify-content-start align-items-center"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="flat-primary"
                    @click.prevent="parametrizarDocumentoCheckList(item)"
                    :disabled="disabledDocIds[item.documento.id] || !acesso.FinanceiroAnexarArquivos"
                  >
                    <feather-icon
                      :icon="
                        item.documentoCheckListParametro.length > 0
                          ? item.documentoCheckListParametro[0].documentoEmitido
                            ? 'FileTextIcon'
                            : 'HardDriveIcon'
                          : 'HardDriveIcon'
                      "
                      class="mr-50"
                    />
                    {{ item.documento.nome }}
                  </b-button>
                  <feather-icon icon="CheckCircleIcon" class="mr-50" v-show="disabledDocIds[item.documento.id]" />
                </div>
                <b-form-file
                  v-model="fileIndividual"
                  ref="fileIndividual"
                  accept=".docx, .pdf"
                  @change="this.fileIndividualEnviar"
                  style="display: none"
                />
              </b-overlay>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Processo Virtual">
          <b-row>
            <b-col md="12">
              <b-table
                striped
                responsive
                class="position-relative"
                :items="filelist"
                :fields="fieldsDocumento"
                :per-page="pesquisar.perPage"
                :current-page="pesquisar.currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :busy="isBusy"
              >
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle mr-1"></b-spinner>
                    <strong>Carregando...</strong>
                  </div>
                </template>
                <template #cell(opcoes)="row">
                  <b-button
                    v-if="acesso.FinanceiroExcluirArquivos"
                    id="button-excluir-docs"
                    ref="button-excluir-docs"
                    variant="danger"
                    class="align-middle ml-50"
                    title="Excluir documento"
                    @click="excluir(row.item)"
                    size="sm"
                  >
                    Excluir
                  </b-button>
                </template>
                <template #cell(originalname)="row">
                  <b-link
                    :disabled="!acesso.FinanceiroAnexarArquivos"
                    href="#"
                    @click="downloadArquivo(row.item)"
                    :title="
                      !acesso.FinanceiroAnexarArquivos ? 'Não possui permissão para abrir o documento' : 'Abrir documento'
                    "
                  >
                    {{ row.item.filename }}
                  </b-link>
                </template>
              </b-table>
              <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
                <!-- pagination -->
                <b-pagination
                  first-number
                  last-number
                  v-model="pesquisar.currentPage"
                  :total-rows="totalRows"
                  :per-page="pesquisar.perPage"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-parametro-documento"
      ref="modal-parametro-documento"
      centered
      :title="'Modelo(s): ' + itemSelecionadoDocumentoTitulo"
      no-close-on-backdrop
      hide-footer
    >
      <b-row>
        <b-col md="12">
          <b-table
            hover
            responsive
            class="position-relative"
            :items="itemsModeloDocumento.documentoModelo"
            :fields="fieldsModelo"
            thead-class="d-none"
            @row-clicked="myRowClickHandler"
          >
            <template #cell(nome)="row">
              <b class="text-primary">{{ row.item.nome.toUpperCase() }}</b>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal id="modal-geraDoc" ref="modal-geraDoc" centered no-close-on-backdrop hide-footer hide-header>
      <contratoGeraContrato @contratoEvent="contratoMethod" :parametro="geraContrato" />
    </b-modal>
  </div>
</template>

<script>
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import useJwt from '@/auth/jwt/useJwt'
  import Ripple from 'vue-ripple-directive'
  import jwt from '@/auth/jwt/useJwt'
  import contratoGeraContrato from './contratoGeraContrato.vue'
  import contratoContrato from '../contrato/contratoContrato.vue'
  import downloadFileAWS from '@/@core/utils/Download-File-AWS.ts'

  export default {
    components: {
      FormWizard,
      TabContent,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      contratoGeraContrato,
      contratoContrato,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
      tomador: {},
    },
    data() {
      return {
        disabledDocIds: [],
        isBusy: false,
        loading: false,
        fileIndividual: null,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        delimiters: ['${', '}'],
        filelist: [],
        checklist: [],
        geraContrato: [],
        allCheck: false,
        itemsDocumento: [],
        itemsDocumentoCheckList: [],
        itemDocumentoSelecionado: [],
        itemSelecionado: {},
        itemSelecionadoDocumento: {},
        itemSelecionadoDocumentoTitulo: '',
        itemsModeloDocumento: [],
        itemsModeloParametro: [],
        documentoSelecionado: [],
        checklistSelecionado: {},
        dadosSelecionadoDocumento: {
          documentoEmitido: null,
          documentoSelecionado: [],
        },
        fieldsModelo: [{ key: 'nome', label: 'Documento Modelo' }],
        fieldsCheckList: [
          { key: 'opcoes', label: 'Opções' },
          { key: 'nome', label: 'Documento' },
        ],
        fieldsDocumento: [
          { key: 'opcoes', label: 'Opções', thStyle: { width: '60px' } },
          { key: 'originalname', label: 'Documento' },
          {
            key: 'origemDocumento',
            label: 'Origem',
            formatter: (value) => (value ? value : 'Administração'),
          },
        ],

        //Padrão Tabela
        sortBy: '',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [6, 25, 50, 100],
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 6, currentPage: 1 },
        striped: true,
        acesso: {
          FinanceiroAnexarArquivos: null,
          FinanceiroExcluirArquivos: null,
        },
        documentosApp: [],
      }
    },
    async mounted() {
      if (this.userData.role == 'master') {
        this.acesso.FinanceiroAnexarArquivos = true
        this.acesso.FinanceiroExcluirArquivos = true
      } else {
        this.acesso.FinanceiroAnexarArquivos = this.userData.GrupoAcesso.FinanceiroAnexarArquivos
        this.acesso.FinanceiroExcluirArquivos = this.userData.GrupoAcesso.FinanceiroExcluirArquivos
      }

      this.$root.$on('atualizarProtocolo', async (data) => {
        if (data) {
          await this.carregarProcessoVirtual()
        }
      })

      this.carregaCheckList()
      this.verificarRequerimentoOk()
    },
    methods: {
      verificarRequerimentoOk() {
        const statusFinanceiro = this.parametro.statusFinanceiro
        const status = ['Quitado', 'Consolidado']

        if (status.includes(statusFinanceiro)) {
          this.fieldsDocumento.splice(0, 1)
        }
      },
      contratoMethod(data, msg) {
        if (data == 'error') {
          this.$refs['modal-geraDoc'].hide()
          this.$refs['modal-parametro-documento'].hide()
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: msg,
                icon: 'error',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
        } else if (data) {
          this.$refs['modal-geraDoc'].hide()
          this.$refs['modal-parametro-documento'].hide()
          this.carregarProcessoVirtual()
        }
      },
      confirmaSelecao() {
        const isChecked = document.querySelectorAll('input:checked').length === this.itemsDocumentoCheckList.length
        this.$emit('protocoloOkEvent', isChecked)
      },
      carregaCheckList() {
        useJwt
          .get(`checkList/${this.userData.institutoSelecionado}`)
          .then((response) => {
            this.checklist = response.data
            this.checklist.forEach((checklist) => {
              checklist.categorias.forEach((item) => {
                if (item.categoria == this.parametro.proposta.segurado.situacaoPrevidenciaria) {
                  this.carregarParametroCheckList(checklist.id)
                }
              })
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarParametroCheckList(item) {
        useJwt
          .get(`checklist/checkListContrato/${item}`)
          .then((response) => {
            this.itemsDocumentoCheckList = response.data
            this.carregarProcessoVirtual()
          })
          .catch((error) => {
            console.error(error)
          })
      },
      parametrizarDocumentoCheckList(item) {
        this.itemSelecionadoDocumento = item
        this.itemSelecionadoDocumentoTitulo = item.documento.nome
        if (item.documentoCheckListParametro[0]?.documentoEmitido) {
          jwt
            .get(`checklist/checkListContratoSelecionado/${item.id}`)
            .then((response) => {
              this.itemsModeloDocumento = response.data
              this.$refs['modal-parametro-documento'].show()
            })
            .catch((error) => {})
        } else {
          this.$refs.fileIndividual.$el.childNodes[0].click()
        }
      },
      excluir(item) {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente excluir o documento?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.isBusy = true
            if (item.origemDocumento === 'Aplicativo') {
              useJwt
                .delete(`reconhecimentoFacial/deleteAnexoRegistroReconhecimentoFacial`, item.id)
                .then((response) => {
                  this.$message.success('Excluído com sucesso!')
                  this.isBusy = !this.isBusy
                  this.carregarProcessoVirtual()
                })
                .catch((error) => {
                  console.error(error)
                  this.$message.error('Erro ao excluir o documento!')
                })
            } else {
              useJwt
                .delete(`financeiro/documento`, item.id)
                .then((response) => {
                  this.isBusy = !this.isBusy
                  this.carregarProcessoVirtual()
                })
                .catch((error) => {
                  console.error(error)
                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: error.response.data.error,
                        icon: 'error',
                        variant: 'danger',
                      },
                    },
                    {
                      position: 'top-right',
                      timeout: 8000,
                    },
                  )
                })
            }
          }
        })
      },
      async carregarProcessoVirtual() {
        this.isBusy = true
        try {
          const response = await useJwt.get(`financeiro/carregarProcessoVirtual/${this.parametro.id}`)
          this.isBusy = false
          this.filelist = response.data
          this.totalRows = response.data.length

          const processoVirtual = []
          const checkList = []

          if (this.parametro.origem === 'Aplicativo') {
            this.documentosApp = await this.carregaDocumentosApp()

            this.documentosApp = this.documentosApp
              .filter((documento) => documento && documento.nome && documento.registroReconhecimentoFacialId) // Verificações adicionais
              .map((documento) => ({
                ...documento,
                filename: documento.nome,
                documentoId: documento.registroReconhecimentoFacialId,
              }))

            this.filelist = [...this.filelist, ...this.documentosApp]
            this.totalRows = this.filelist.length
          }

          this.filelist.forEach((f) => {
            if (f.documentoId !== null) {
              processoVirtual.push(f.documentoId)
            }
          })

          this.disabledDocIds = this.filelist
            .flatMap((file) => {
              if (file.documento && file.documento.documentoCheckList) {
                return file.documento.documentoCheckList
                  .filter((checklist) => checklist.documentoId !== null)
                  .map((checklist) => ({ [checklist.documentoId]: true }))
              }
              return []
            })
            .reduce((prev, cur) => ({ ...prev, ...cur }), {})

          this.$emit('atualizarManusearDocumentosEvent', {
            files: this.filelist,
            checklist: this.itemsDocumentoCheckList,
          })
          this.$emit('protocoloOkEvent', checkList.filter((x) => !processoVirtual.includes(x)).length === 0)
        } catch (error) {
          this.isBusy = false
          console.error(error)
        }
      },
      async downloadArquivo(item) {
        const fileName = item.originalname
        this.isBusy = true
        await downloadFileAWS(item.s3Key, fileName)
          .then((response) => {
            if (response) {
              this.$message.success('Download realizado com sucesso!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao realizar o download!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      myRowClickHandler(row, index) {
        try {
          this.downloadModelo(row.id)
        } catch (error) {
          this.$message.error('Erro ao gerar a minuta')
          console.error(error)
          this.$refs['modal-geraDoc'].hide()
          this.$refs['modal-parametro-documento'].hide()
        }
      },
      downloadModelo(item) {
        useJwt
          .downloadDocumento(`cadastro/documentoModelo/download`, item)
          .then((response) => {
            let blob = new Blob([response.data], {
              type: response.data.mimeType,
            })

            this.geraContrato.s3url = response.data.s3url
            this.geraContrato.contratoId = this.parametro.id
            this.geraContrato.userId = this.userData.id
            this.geraContrato.nomeDocumento = this.itemSelecionadoDocumento.documento.nome
            this.geraContrato.idDocumento = this.itemSelecionadoDocumento.documentoId

            this.$refs['modal-geraDoc'].show()
          })
          .catch((error) => {
            console.error(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'O arquivo desejado não foi encontrado',
                  icon: 'error',
                  variant: 'danger',
                },
              },
              {
                position: 'top-right',
                timeout: 8000,
              },
            )
          })
      },

      fileIndividualEnviar(event) {
        this.loading = true

        // Função para remover acentos de uma string
        const removerAcentos = (s) => s.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

        // Verificar se há arquivos selecionados
        if (event.target.files.length > 0) {
          const originalFile = event.target.files[0]
          const nomeSemAcentos = removerAcentos(originalFile.name)

          // Criar um novo arquivo com o nome sem acentos
          const arquivoSemAcentos = new File([originalFile], nomeSemAcentos, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
          })

          const formData = new FormData()
          formData.append('userId', this.userData.id)
          formData.append('financeiroId', this.parametro.id)
          formData.append('file', arquivoSemAcentos) // Adiciona o arquivo modificado ao formData
          formData.append('documentoId', this.itemSelecionadoDocumento.documentoId)
          formData.append('origemDocumento', 'Administracao')

          // Substitua 'useJwt.postDocumento' pelo seu método de upload
          useJwt
            .postDocumento(`financeiro/documentacaoProcesso`, (event) => {}, formData)
            .then(async (response) => {
              this.loading = !this.loading
              await this.carregarProcessoVirtual()
            })
            .catch((error) => {
              console.error(error)
              this.loading = false
            })
        } else {
          // Se não houver arquivos selecionados, apenas desativa o loading
          this.loading = false
        }
      },
      async carregaDocumentosApp() {
        return new Promise((resolve, reject) => {
          useJwt
            .get(`reconhecimentoFacial/getAnexoRegistroReconhecimentoFacial/${this.tomador.cpf}`)
            .then(async (response) => {
              resolve(response.data)
            })
            .catch((error) => {
              console.error(error)
              reject(error)
            })
        })
      },
    },
  }
</script>
<style lang="scss">
  [v-cloak] {
    display: none;
  }

  .d-none {
    display: none;
  }
</style>
