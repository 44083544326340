<template>
  <div>
    <b-card border-variant="primary" v-show="this.totalRows === 0">
      <h3 class="text-black mb-2">Não há parcelas disponíveis para este contrato</h3>
      <b-card-text class="text-black mb-5"> Nenhuma parcela foi encontrada no cadastrado deste contrato. </b-card-text>
    </b-card>

    <!-- Tabela -->
    <b-table
      v-show="this.totalRows"
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :current-page="pesquisar.currentPage"
      :items="tabela"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>

      <!-- <template #cell(check)="row">
        <b-form-checkbox
          v-if="row.item.parcelaRenegociada"
          v-model="selectedItems"
          :value="row.item.id"
          :name="'row-' + row.index"
        ></b-form-checkbox>
      </template> -->

      <template #cell(parcela)="row">
        {{ row.item.parcela }}
      </template>

      <template #cell(valor)="row">
        {{ row.item.valor ? formatarValor(row.item.valor) : formatarValor(0) }}
      </template>

      <template #cell(taxa)="row">
        {{ row.item.taxa ? formatarValorPorcentagem(row.item.taxa) : formatarValorPorcentagem(0) }}
      </template>

      <template #cell(encargo)="row">
        {{ row.item.encargo ? row.item.encargo : '0.00' }}
      </template>

      <template #cell(vencimento)="row">
        {{ formatarData(row.item.vencimento) }}
      </template>

      <template #cell(pagamento)="row">
        {{ row.item.pagamento ? 'Pago' : null }}
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-center flex-wrap pt-0">
      <!-- pagination -->
      <b-pagination
        first-number
        last-number
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <!-- INICIO DO VERIFICA SENHA -->
    <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
      <AutenticaSenha @autenticarEvent="autenticarMethod" />
    </b-modal>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import vSelect from 'vue-select'
  import { VueAutosuggest } from 'vue-autosuggest'
  import { formatarValor, formatarData, formatarValorPorcentagem } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      vSelect,
      VueAutosuggest,
      AutenticaSenha,
      formatarData,
      formatarValorPorcentagem,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        isBusy: true,
        formatarValor,
        formatarValorPorcentagem,
        formatarData,
        number: {},
        suggestions: [],
        timeout: null,
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
        },
        required,

        //Padrão Tabela
        sortBy: '',
        totalRows: 1,
        sortDesc: true,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        pesquisar: { tipoOpcoes: 1, descricao: '', perPage: 10, currentPage: 1 },
        striped: true,

        //Campos tabela
        fields: [
          { key: 'parcela', label: 'Nº da Parcela', sortable: true },
          { key: 'valor', label: 'Valor', sortable: true },
          { key: 'taxa', label: 'Taxa' },
          { key: 'vencimento', label: 'Vencimento' },
          { key: 'pagamento', label: 'Pagamento', sortable: true },
        ],
        tabela: [],
        selectedItems: [],
        habilitaBaixarParcelas: false,
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
    },
    mounted() {
      this.carregarGrid()
    },
    methods: {
      carregarGrid() {
        this.isBusy = true
        useJwt
          .pesquisar(`financeiro/GetbuscaParcelas/${this.parametro.id}`)
          .then((response) => {
            this.isBusy = !this.isBusy
            this.tabela = response.data
            this.totalRows = response.data.length
          })
          .catch((error) => {
            console.error(error)
          })
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()
        }
      },
    },
  }
</script>

<style lang="scss"></style>
