<script>
  import contrato from './contrato.vue'

  export default {
    components: {
      contrato,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
    mounted() {},
  }
</script>
<template>
  <div>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="PieChartIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Contratos</span>
        </template>
        <contrato />
      </b-tab>
    </b-tabs>
  </div>
</template>
