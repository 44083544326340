<template>
  <div>
    <b-progress :max="100">
      <b-progress-bar
        animated
        :value="progress"
        variant="primary"
        class="progress-bar-primary"
        :label="`${((progress / 100) * 100).toFixed(2)}%`"
      />
    </b-progress>
    <b-form-file
      multiple
      v-model="files"
      ref="file"
      accept=".docx, .pdf"
      placeholder="Escolha um arquivo"
      drop-placeholder="Deixe seu arquivo aqui..."
      browse-text="Buscar"
      :file-name-formatter="formatNames"
    />
    <div class="alert alert-light" role="alert">{{ message }}</div>
    <div class="card">
      <div class="card-header">Lista de arquivos enviados</div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item" v-for="(file, index) in files" :key="index">
          <a>{{ file.name }}</a>
        </li>
      </ul>
    </div>
    <div class="mt-1">
      <b-button variant="btn btn-success" @click="upload"> Enviar Arquivo </b-button>
    </div>
  </div>
</template>
<script>
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    props: {
      parametro: {},
    },
    methods: {
      formatNames(files) {
        if (files.length === 1) {
          return files[0].name
        }
        return `${files.length} arquivos selecionados`
      },
      onProgress(percent) {},
      upload() {
        this.progress = 0
        for (var i = 0; i < this.$refs.file.files.length; i++) {
          const formData = new FormData()

          formData.append('userId', this.userData.id)
          formData.append('financeiroId', this.parametro.id)
          let file = this.$refs.file.files[i]

          // Normaliza o nome do arquivo
          let normalizedFileName = this.normalizeFileName(file.name)
          // Cria um novo File com o nome normalizado
          let normalizedFile = new File([file], normalizedFileName, {
            type: file.type,
          })

          formData.append('file', normalizedFile)

          useJwt
            .postDocumento(`financeiro/documentacaoProcesso`, (event) => {}, formData)
            .then(async (response) => {
              this.$emit('uploadEvent', true)
            })
            .catch((error) => {
              console.error(error)
              this.$message.error(String(error))
            })
        }
        this.selectedFiles = undefined
      },
      normalizeFileName(fileName) {
        return fileName
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^a-zA-Z0-9.]/g, ' ')
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        selectedFiles: [],
        currentFile: undefined,
        progress: 0,
        message: '',
        fileInfos: [],
        files: null,
      }
    },
  }
</script>
