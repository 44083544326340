import { check } from './check'
import { blank } from './blank'
import { keyIcon as Kicon } from './key-icon'
import { logoAgenda } from './logo-agenda'

export function base64DataURLToArrayBuffer(dataURL) {
  const stringBase64 = dataURL
  let binaryString
  if (typeof window !== 'undefined') {
    binaryString = window.atob(stringBase64)
  } else {
    binaryString = new Buffer(stringBase64, 'base64').toString('binary')
  }
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    const ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes.buffer
}

export const checkIcon = base64DataURLToArrayBuffer(check)
export const blankIcon = base64DataURLToArrayBuffer(blank)
export const agendaIcon = base64DataURLToArrayBuffer(logoAgenda)
export const keyIcon = base64DataURLToArrayBuffer(Kicon)
